@font-face {
  font-family: jackArmstrong;
  src: url(jackArmstrong.ttf);
}

body {
  height: 100dvh;
  width: 100%;
}

.adminImage {
  width: 10vw;
}

.white-space-pre-line {
  white-space: pre-line;
}

.alert-info {
    --bs-alert-color: #1a1a1a;
    --bs-alert-bg: #FEF5D3;
    --bs-alert-border-color: #1a1a1a;
    border-radius: 12.5px;
}

@supports (-webkit-text-size-adjust: 100%) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}

@supports (text-size-adjust: 100%) {
  body {
    text-size-adjust: 100%;
  }
}

@supports (text-align: -webkit-match-parent) {
  th {
    text-align: -webkit-match-parent;
  }
}

@supports (text-align: match-parent) {
  th {
    text-align: match-parent;
  }
}

@supports (text-align: inherit) {
  th {
    text-align: inherit;
  }
}

#root {
  width:100%;
  height:100dvh;
}

.navbar-brand {
  width: auto;
}

#photo-col {
  margin: 0;
  padding: 0;
}

#product-col {
  margin: 0;
  padding: 0;
}

.photo-card-image {
  object-fit: contain;
  object-position: center;
  border: 1px solid black;
  border-radius: 3px;
}

.grid-item-photo {
  height: 300px;
  aspect-ratio: 1/1;
  cursor: grab;
}

.photo-card {
  aspect-ratio: 1/1;
  margin: 1px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767.5px) {
  a.navbar-link.active.nav-link {
    /* border-top: 2px solid #FEF5D3!important;
    border-bottom: 2px solid #FEF5D3!important; */
    
    /* border-top-right-radius: 15px;
    border-bottom-right-radius: 15px; */
    color:#FED9E0;
    box-sizing: border-box;
  }

  a.navbar-link.nav-link {
    box-sizing: border-box;
  }

  .navbar-nav .nav-link {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    /* height: calc(100% / 9); */
  }
}

@media (max-width: 767.5px){ /* Phones */
  .navbar-toggler-icon {
    background-color: #21534A;
    border: 2px solid #FED9E0;
    padding: 25px;
    border-radius: 15px;
  }
  .navbar-toggler {
    border: none;
    height:54.8px;
    width: 54.8px;
    padding: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 0;
    position: sticky;
    top: 0;
  }
  .navbar-row {
    justify-content: right;
  }
  #basic-navbar-nav {
    background-color: #1a1a1a;
    color: #FEF5D3;
    width: calc(100vw - 63px);
    height: auto;
    overflow: hidden;
  }

  .swiper-image {
    margin-top: 20px;
  }

  .navbar-link {
    text-align: center;
    padding: 0;
    margin-top: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  

  .nav-title {
    color: #FEF5D3;
    display: flex;
    text-align: center;
  }

  .big-logo {
    height: auto;
    width: auto;
  }
  .logo-row {
    margin-top: 11.76vw;
    margin-left: 11.76vw;
    margin-right: 11.76vw;
    margin-bottom: 0px;
  }
  .toggle-button {
    border: 5px solid black;
    width: 45px;
    height: 45px;
    margin: 0px;
    padding: 0px;
  }
  .align-toggle-right {
    justify-content: right;
    width: 100vw;
  }
  .toggle-container {
    width: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px){ /* Tablets */
  .dropdown-toggle {
    color: #1a1a1a
  }

  .big-logo {
    height: auto;
    width: auto;
  }
  .logo-row {
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 0px;
  }
  .toggle-button {
    border: 5px solid black;
    width: 45px;
    height: 45px;
    margin: 0px;
    padding: 0px;
  }
  .align-toggle-right {
    justify-content: right;
    width: 100vw;
  }
  .toggle-container {
    width: 45px;
  }
}

.zero {
  margin: 0;
  padding: 0;
}

.shipping-details-font {
  font-weight: bold;
}

.input-font {
  font-family: 'Comic Neue';
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}

.gold-text {
  color: #FEF5D3;
}

.margin-right-0 {
  margin-right: 0;
}

.paginate {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.pagination-item {
  margin-left: 14px;
  margin-right: 6px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.product-card {
  background-color: #1a1a1a;
  border: solid #FEF5D3 3px;
  height: 100%;
  overflow: hidden;
  margin:1.5px;
}

.product-card-image {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  border:#1a1a1a solid 3px;
}

.photo-card {
  background-color: black;
  border: solid #FEF5D3 2px;
  overflow: hidden;
}

.product-row {
  padding-bottom: 3px;
}

.product-card-title {
  height: 50%;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration-line: none;
  color: #cccccc!important;
}

.product-card-price {
  text-align: right;
}

.card-body {
  padding: 15px;
}

.tattoo-card-price {
  height: 35%;
  margin: 0px;
  padding-top: 15px;
  padding-bottom: 0px;
  text-decoration-line: none;
}



.red-text{
  color: rgb(255, 50, 50);
}

.green-text{
  color: #21534A;
}

.product-name {
  color: #FEF5D3;
  text-decoration-line: none;
}

.image-fill {
  width: 100%;
  height: 100%;
}

.fill-height {
  height: 100%;
}

* {
  font-family: jackArmstrong;
  background-color: rgba(255, 255, 255, 0);
}

.bring-forward {
  z-index: 1;
}

.bring-backwards-1 {
  z-index: -1;
}

.logoHeader {
  background-color: rgba(255, 255, 255, 0);
}

.bottom-background {
  z-index: -2;
  background-color: #FED9E0;
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 100vw;
	
  /* Set up proportionate scaling */
  width: 100vw;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

img.bg-mobile {
  z-index: -1;
  /* Set rules to fill background */
  min-height: 100vh;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: 100vh;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

img.bg-desktop {
  display:none;

  z-index: -1;
  /* Set rules to fill background */
  min-height: 100vh;
  min-width: 100%;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: 100vh;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

@media (orientation: landscape) {
  img.bg-mobile{
    display:none;
  }

  img.bg-desktop{
    display: block;
  }
}

/* @media screen and (max-width: 1024px) { Specific to this particular image
  img.bg {
    left: 50%;
    margin-left: -512px;   50%
  }
} */

#mainContainer {
}

.footer {
  color: #21534A;
  font-size: 0.65rem;
}

.copyright-bar {
  width: 100svw;
  height: auto;
  /* background-color: #1a1a1a; */
  color: #1a1a1a;
  margin-bottom: auto;
  padding-left: 0px;
  padding-right: 0px;
}

@media(min-width: 375px) {
  .footer {
    font-size: 0.75rem;
  }
}

@media(min-width: 425px) {
  .footer {
    font-size: 0.85rem;
  }
}

@media(min-width: 576px) {
  .footer {
    font-size: 1rem;
  }
}

.homepage-address-bar {
  width:100vw;
  max-width: none;
}

swiper-slide {
  background-color: rgba(255, 255, 255, 0);
}

.swiper-wrapper {
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
}

.swiper-slide-next {
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
}

.swiper-slide-active {
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
}

/* .pink-background {
  background-color: #FED9E0;
  color: #FED9E0;
} */

.padding-top-0 {
  padding-top: 0;
}

@media (max-width: 767.5px){
  .big-logo {
    height: auto;
    width: auto;
  }
  
  .toggle-button {
    border: 5px solid black;
    width: 45px;
    height: 45px;
    margin: 0px;
    padding: 0px;
  }
  .align-toggle-right {
    justify-content: right;
    width: 100vw;
  }
  .toggle-container {
    width: 45px;
  }
}

.display-flex {
  display: flex;
}

.navbar-toggler-icon {
  height: 45px;
  width: 45px;
}

.margin-padding-0 {
  margin: 0;
  padding: 0;
}

.big-logo {
  height: auto;
  width: auto;
}

main {
  min-height: 0vh;
  background-color: #FED9E0;
}

/* .navbar-brand {
  align-items: center;
} */

.ml-auto {
  margin-left: auto;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
  margin-bottom: 0px;
}


h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-point5rem {
  margin-top: 0.5rem;
}

.margin-top-1point5rem {
  margin-top: 1.5rem;
}

.text-decoration-line-none {
  text-decoration-line: none;
}

/** Swiper styles **/

#swiper {
  margin-top: 10px;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  justify-content: center;

  padding-top: 0px;
  padding-bottom: 0px;
}

#swiperTwo {
  margin-top: 5px;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  justify-content: center;

  padding-top: 0px;
  padding-bottom: 0px;
}

#swiper-slide {
  position:absolute;
  right:71%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  aspect-ratio: 1/1;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  background-color: rgba(0, 0, 0, 0);

  /* border-radius: 10px; */
  /* border: 2px solid #FEF5D3; */
}

#swiper-slide-image {
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  border: 2px solid #1a1a1a;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;

  z-index: 0;
}

#swiper-slide-image:hover {
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  border: 2px solid #1a1a1a;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#swiper-slide-image:active {
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  border: 2px solid #1a1a1a;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media (min-width: 768px) { /* TABLET STYLES*/
  #navbar-row {
    margin-top: 5px;
  }

  .dropdown-menu {
    background-color: #FEF5D3;
    border: #1a1a1a 2px solid;
    border-top: none;
    padding: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .navbar-nav {
    --bs-nav-link-color: #1a1a1a;
    --bs-nav-link-hover-color: #1a1a1a;
    --bs-nav-link-disabled-color: #1a1a1a;
  }

  .dropdown-item {
    color:#1a1a1a!important;
    padding-top:5px !important;
    padding-bottom:5px !important;
  }

  .dropdown-item.active {
    color: #FEF5D3!important;
  }

  #main-navbar > div > div.dropdown-menu.show > a:nth-child(1) {
    padding-top: 10px !important;
  }

  #main-navbar > div > div.dropdown-menu.show > a:nth-last-child(1) {
    padding-bottom: 10px !important;
    border-bottom: #FED9E0 1px solid;
  }

  .dropdown-item:hover {
    color: #FED9E0!important;
    background-color: #1a1a1a;
  }

  .dropdown-toggle {
    color: #1a1a1a;
  }

  .dropdown-toggle:hover {
    color: #21534a;
  }

  .dropdown {padding-top: .715rem;
    padding-bottom: .715rem;
  }

  

  .navbar-link {
    /* color: #1a1a1a; */
    text-align: center;
    margin-left: 0px;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }

  .navbar-link:hover {
    color: #21534a;
  }

  .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: #FEF5D3;
    background-color:#1a1a1a;
    border-radius: 10px;
    margin-bottom:2px;
    padding-bottom: 0px;
  }

  #main-navbar {
    border-bottom: 2px solid #1a1a1a;
    width:100%;
  }

  

  .align-items-center {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  #logo-container {
    align-items: center;
  }

  #navbar-align {
    align-items: center!important;
  }

  #logo-col {
    display:inline-block;
    align-items: center;
    justify-content: center;
  }

  

  .navbar-row {
    display:inline-block;
    width:100%;
  }

  #swiper {
    margin-top: 20px;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    /* height: 100%; */
  
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #swiperTwo {
    margin-top: 5px;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    /* height: 100%; */
  
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  }

@media (min-width: 992px) {
  /* div.dropdown-menu.show{
    width: calc(100% - 10%);
    margin-left: 5%;
  } */

  .big-logo {
    margin-top: 30px;
    height: 28.79vw;
    width: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  #swiper {
    margin-top: 20px;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    /* height: 100%; */
  
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #swiperTwo {
    margin-top: 5px;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    /* height: 100%; */
  
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  #swiper-slide {
    position:absolute;
    right:13.25%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    aspect-ratio: 1/1;
    color: #fff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
  
    background-color: rgba(0, 0, 0, 0);
  
    border-radius: 0px;
  }
  
  #swiper-slide-image {
    object-fit: cover;
    object-position: center;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
  
    z-index: 0;
  }

  /* #swiper:hover {
    overflow:visible;
    width:100%;
  }
  #swiperTwo:hover{
    overflow:visible;
  }
  #swiper-slide:hover {
    overflow:visible;
  }
  #swiper-slide-image:hover {
    transition: transform .2s;
  }
  #swiper-slide-image:hover {
    transform: scale(1.075);
    z-index: 1;
    border: 3px solid #1a1a1a;
  } */
}

@media (min-width: 1200px) {
  #swiper {
    margin-top: 20px;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    /* height: 100%; */
  
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #swiperTwo {
    margin-top: 5px;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    /* height: 100%; */
  
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  #swiper-slide {
    object-fit: fill;
    position:absolute;
    right:13.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    aspect-ratio: 1/1;
    color: #fff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
  
    background-color: rgba(0, 0, 0, 0);
  
    border-radius: 0px;
  }
  
  #swiper-slide-image {
    object-fit: cover;
    object-position: center;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  /* #swiper:hover {
    overflow:visible;
  }
  #swiperTwo:hover {
    overflow:visible;
  }
  #swiper-slide:hover {
    overflow:visible;
  }
  #swiper-slide:hover {
    transition: transform .2s;
  }
  #swiper-slide-image:hover {
    transform: scale(1.075);
    z-index: 1;
    border: 3px solid #1a1a1a;
  } */
}

#navbar-align {
  align-items: center!important;
  justify-content: center;
  text-align: center;
}

.grid-dropzone-container {
  display: flex;
  touch-action: none;
  width: 100%;
  margin: 0;
}

.grid-dropzone {
  flex: 1;
  height: 100%;
}

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: grab;
}

.grid-item:active {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: grabbing;
}

.grid-item-content {
  width: 400px;
  height: 400px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Swiper Navbar Styles */
@media (max-width: 767.5px) { /* Phones */
  #SwiperNavbar {
    padding-top: 0px;
    padding-bottom: 0px;
    /* height: calc(40vw - 5px); */
    height: auto;
    margin-top: 5px;
    background-color: #1a1a1a;
    border-top: #FEF5D3 5px solid;
    border-bottom: #FEF5D3 5px solid;
  }
  #SwiperNavbarContainer {
    width: 100%;
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0;
  }
  #SwiperNavbarRow {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .SwiperNavbarCol {
    position: relative;
    align-self: stretch;
    flex: none;
    padding: 0;
    width: 100%;
    min-height: calc(100% / 3);
    height: calc(100% / 3);
    max-height: calc(100% / 3);
    align-items: stretch;
  }
  .SwiperNavLink {
    align-self: auto;
    border:#FEF5D3 3px solid;
    color: #FEF5D3;
    border-radius: 5px;
    background-color: #1a1a1a;
    display: flex;
    height: calc(100% / 3);
    align-items: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLink:hover {
    border:#FEF5D3 3px solid;
    color: #1a1a1a;
    border-radius: 5px;
    background-color: #FEF5D3;
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLinkButton {
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 576px) { /* Tablets */
  #SwiperNavbar {
    padding-top: 0px;
    padding-bottom: 0px;
    /* height: calc(40vw - 5px); */
    height: auto;
    margin-top: 5px;
    background-color: #1a1a1a;
    border-top: #FEF5D3 5px solid;
    border-bottom: #FEF5D3 5px solid;
  }
  #SwiperNavbarContainer {
    width: 100%;
    height: 100%;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0;
  }
  #SwiperNavbarRow {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .SwiperNavbarCol {
    flex: none;
    padding: 0;
    width: 100%;
    height: calc(100% / 3);
  }
  .SwiperNavLink {
    border:#FEF5D3 3px solid;
    color: #FEF5D3;
    border-radius: 5px;
    background-color: #1a1a1a;
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLink:hover {
    border:#FEF5D3 3px solid;
    color: #1a1a1a;
    border-radius: 5px;
    background-color: #FEF5D3;
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLinkButton {
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 768px) { /* Laptops/Desktops */
  #SwiperNavbar {
    padding-top: 0px;
    padding-bottom: 0px;
    height: auto;
    margin-top: 5px;
    background-color: #1a1a1a;
    border-top: #FEF5D3 5px solid;
    border-bottom: #FEF5D3 5px solid;
  }
  #SwiperNavbarContainer {
    width: 100%;
    height: 100%;
    margin-left: 3.1%;
    margin-right: 3.1%;
    padding: 0;
  }
  #SwiperNavbarRow {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SwiperNavbarCol {
    flex: none;
    margin-top: auto;
    margin-bottom: auto;
    width: calc(100% / 3);
    height: 100%;
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SwiperNavLink {
    border:#FEF5D3 3px solid;
    color: #FEF5D3;
    border-radius: 5px;
    background-color: #1a1a1a;
    display: flex;
    height: 75px;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLink:hover {
    border:#FEF5D3 3px solid;
    color: #1a1a1a;
    border-radius: 5px;
    background-color: #FEF5D3;
    display: flex;
    height: 75px;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLinkButton {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

@media (min-width: 992px) { /* Laptops/Desktops */
  #SwiperNavbar {
    padding-top: 0px;
    padding-bottom: 0px;
    height: auto;
    margin-top: 5px;
    background-color: #1a1a1a;
    border-top: #FEF5D3 5px solid;
    border-bottom: #FEF5D3 5px solid;
  }
  #SwiperNavbarContainer {
    width: 100%;
    height: 100%;
    margin-left: 12.5%;
    margin-right: 12.5%;
    padding: 0;
  }
  #SwiperNavbarRow {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SwiperNavbarCol {
    flex: none;
    margin-top: auto;
    margin-bottom: auto;
    width: calc(100% / 3);
    height: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SwiperNavLink {
    border:#FEF5D3 3px solid;
    color: #FEF5D3;
    border-radius: 5px;
    background-color: #1a1a1a;
    display: flex;
    height: 75px;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLink:hover {
    border:#FEF5D3 3px solid;
    color: #1a1a1a;
    border-radius: 5px;
    background-color: #FEF5D3;
    display: flex;
    height: 75px;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
  }
  .SwiperNavLinkButton {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

/* Navbar Toggle Button */
@media (max-width: 767.5px){
  #navbar-row {
    /* height: 54.8px; */
    overflow: visible;
  }

  #navbar-toggle-button {
    /* align-self: auto; */
    position: fixed;
    top: 10px;
    right: 0px;
    height: 54.8px;
    z-index: 1000;
    align-self: flex-start;
    /* overflow: visible; */
    /* border: 2px solid #FED9E0; */
    border-radius: 15px;
  }

 
}


.justify-content-center {
  justify-content: center!important;
}

@media(max-width: 991.5px) {
  .mainNavbarZoom {
    transition: transform .2s;
  }
  .mainNavbarZoom:active {
    transform: scale(1.2);
  }
  
  .swiperNavbarZoom {
    transition: transform .2s;
  }
  
  .swiperNavbarZoom:active {
    transform: scale(1.15);
  }
}

@media(min-width: 992px) {
  .mainNavbarZoom {
    transition: transform .2s;
  }
  .mainNavbarZoom:hover {
    transform: scale(1.1);
  }
  
  .swiperNavbarZoom {
    transition: transform .2s;
  }
  
  .swiperNavbarZoom:hover {
    transform: scale(1.075);
  }
}



.dropDownHover:hover {
  color: #FED9E0!important;
}

.photoButton {
  background-color: #ffffff00!important;
  width: auto!important;
  height: auto!important;
  padding: 0;
  margin: 1.5px;
  border-radius: 3px;
  cursor: default!important;
}



@media (min-width: 768px) {
  .expandPortfolioPhoto {
    transition: transform .2s;
  }
  .expandPortfolioPhoto:hover {
    transform: scale(1.03);
    z-index: 2;
    opacity: 90%;
  }
  #portfolioModal {
    background-color: rgba(0, 0, 0, 0)!important;
  }
  .modal-content {
    background-color: rgba(0, 0, 0, 0)!important;
    border: none!important;
  }
  .modal-body {
    padding: 0!important;
  }
  #portfolioCarousel {
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  #portfolioCarousel > div.carousel-indicators > button {
    display: none;
  }
  .carousel-inner {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 70vw;
    align-self: center;

    display: flex;
  }
  .carousel img {
    height: auto;
    width: 70vw;
    margin: auto;
  }
  #portfolioCarouselItem {
    width: 70vw;
    margin: auto;
    height:100%;
    align-self: center;
    justify-content: center;
  }

  .carousel-item-next, .carousel-item-prev, .carousel-item.active {
    display: flex;
  }

  .carousel-item-start {
    width: 70vw;
    margin: auto;
    height:100%;
    align-self: center;
    justify-content: center;
    display: flex;
  }

  .carousel-item-end {
    width: 70vw;
    margin: auto;
    height:100%;
    align-self: center;
    justify-content: center;
  }

  #portfolioSwiper {
    width: 95%;
    height:100%;
    margin-top: 0vw;
    margin-bottom: 0vw;
  }

  #portfolioSwiper > div.swiper-wrapper {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
  }

  .portfolioSwiperSlide {
    width: 100%;
    height: 100%;
    display: flex!important;
    align-items: center;
    justify-content: center;
  }

  .portfolioSwiperSlideImage {
    width: auto;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
  }
}

@media (max-width: 767.5px) {
  #mainScreenContent {
    padding-top: 0px!important;
  }
}

/* ABOUT SCREEN STYLING */

#aboutScreenImageCard {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  --bs-card-border-width: 0px;
  border:#FEF5D3 3px solid;
  border-bottom: none;
  background-color: #1a1a1a;
}
#aboutScreenImage {
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  border-width: 0px;
  border:#1a1a1a 1px solid;
  border-bottom: none;
}
#aboutScreenTextCard {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  --bs-card-border-width: 0px;
  border:#FEF5D3 3px solid;
  border-top: none;
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  color:#FEF5D3;
  text-align: center;
  background-color: #1a1a1a;
}

#aboutScreenText {
  border-top: 1px solid #FEF5D3;
  border-bottom: 1px solid #FEF5D3;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

@media (max-width: 575.5px) {
  #aboutImageCol {
    padding-left: 3px;
    padding-right: 3px;
  }
  #aboutTextCol {
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media (min-width: 576px) {
  #aboutScreenImageCard {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    --bs-card-border-width: 0px;
    /* height:100%; */
    border: none;
    border-top:#FEF5D3 3px solid;
    border-bottom:#FEF5D3 3px solid;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
  
  }
  #aboutScreenImage {
    margin: auto;
    margin-top: 0;
    /* padding-top: 30px; */
    /* padding-bottom: 30px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-width: 0px;
    border: none;
    max-height: calc(100dvh - 10px);
    object-fit: cover;
    object-position: center;
  }
  #aboutScreenTextCard {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    --bs-card-border-width: 0px;
    border:#FEF5D3 3px solid;
    padding: 15px;
    color:#FEF5D3;
    text-align: center;
    background-color: #1a1a1a;
    height: 100%;
    justify-content: center;
  }
  #aboutScreenTextBody {
    display: flex;
    align-items: center;
  }

  #aboutScreenText {
    border-top: 1px solid #FEF5D3;
    border-bottom: 1px solid #FEF5D3;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

@media (min-width: 576px) and (max-width: 767.5px) {
  #aboutImageCol {
    padding-right: 1.5px;
    padding-left: 5px;
  }
  #aboutTextCol {
    padding-left: 1.5px;
    padding-right: 3px;
  }
}

@media (min-width: 768px) /*and (max-width: 767.5px)*/ {
  #aboutImageCol {
    padding-right: 1.5px;
    /* padding-left: 5px; */
  }
  #aboutTextCol {
    padding-left: 1.5px;
    /* padding-right: 3px; */
  }
}

@media (min-width: 992px) {
  /* #aboutScreenImageCard {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
    --bs-card-border-width: 0px;
    height:100%;
    border:#FEF5D3 3px solid;
    border-right: none;
  } */
  /* #aboutScreenImage {
    border-top-left-radius: 7.5px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 0px;
    border-width: 0px;
    border: #1a1a1a 1px solid;
  }
  #aboutScreenTextCard {
    border-top-right-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    --bs-card-border-width: 0px;
    border:#FEF5D3 3px solid;
    border-left: none;
    padding: 16px;
    color:#FEF5D3;
    text-align: center;
    background-color: #1a1a1a;
    height: 100%;
    justify-content: center;
  } */
  #aboutScreenTextBody {
    display: flex;
    align-items: center;
  }
  #aboutScreenText {
    border-top: 1px solid #FEF5D3;
    border-bottom: 1px solid #FEF5D3;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  /* #aboutImageCol {
    padding-right: 0px;
  }
  #aboutTextCol {
    padding-left: 0px;
  } */
}

/* NAVBAR TOGGLER ICON */

.navbar-dark {
  --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28254, 217, 224, 1.00%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e);
}

/* FAQ SCREEN STYLING */

.accordion-button:focus {
  box-shadow: none!important;
}

#faqCard {
  border: #FEF5D3 3px solid!important;
  border-radius: 10px!important;
  overflow: hidden;
  color:#FED9E0;
  background-color: #1a1a1a!important;
}

#faqCard > div > div > div > div > div.faqAccordionItem.accordion-item > div > div {
  padding: 20px!important;
  padding-left: 27.5px!important;
  padding-right: 27.5px!important;
}

#faqCard > div > div > div > div:nth-child(1) > div.faqAccordionItem.accordion-item > h2 {
  padding: 0px!important;
}

#faqCard > div > div > div > div > div.faqAccordionItem.accordion-item > h2 > button {
  padding: 20px!important;
}

#faqCard > div > div > div > div > div.faqAccordionItem.accordion-item > h2 {
  padding: 0px!important;
}

.faqAccordionItem {
  border-bottom: none!important;
}

.faqItemDivider {
  border-bottom: 1px solid #FEF5D3!important;
  margin-right: 20px;
  margin-left: 20px;
}

#faqCard > div > div > div:nth-last-child(1) > div > div.faqItemDivider {
  border-bottom: 0px!important;
}

/* FAQ ACCORDION VARIABLES */

.accordion {
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FEF5D3%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);

  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%231a1a1a%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);

  /* INACTIVE QUESTION COLOR */
  --bs-accordion-btn-color: #FEF5D3 !important;

  /* ACTIVE QUESTION COLORS */
  --bs-accordion-active-bg: #FEF5D3 !important;
  --bs-accordion-active-color: #1a1a1a !important;

  /* ACCORDION BACKGROUND COLOR */
  --bs-accordion-bg: #1a1a1a !important;

  --bs-accordion-color: #FED9E0 !important;
}

@media (max-width: 767.5px) {
  #mainNavDropdown {
    display: none;
  }
}

#mainNavDropdown {
  padding-top: .715rem;
  padding-bottom: 9.440px;
  margin-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px
}

@media (min-width: 768px) {
  .mobileOnly {
    display: none!important;
  }
}

@media (max-width: 767.5px) {
  #mobileNavLogo {
    height: 100%;
    width: auto;
    padding-top: calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2);
    padding-bottom: calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2);
  }

  #mobileNavHeader {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    height: calc(calc(100% / 12) * 4);
    justify-content: center!important;
    /* border-bottom: 1px solid #FEF5D3; */
  }

  a.navbar-link.nav-link {
    padding: 0px !important;
    height: calc(calc(100dvh / 12) * 1) !important;
    line-height: calc(calc(100dvh / 12) * 1) !important;
    width: calc(calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2) + 185px);
    max-width: calc(100vw - 65px);
    margin-left: auto;
    margin-right: auto;
  }

  a.navbar-link.nav-link.active {
    padding: 0px !important;
    box-sizing: border-box;
    border-top: 2px solid #FEF5D3!important;
    border-bottom: 2px solid #FEF5D3!important;
    border-left: none;
  }

  #main-navbar > a.nav-link > div {
    padding: 0px !important;
    height: 100% !important;
    line-height: calc(calc(100dvh / 12) * 1) !important;
    width: calc(calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2) + 185px);
    max-width: calc(100vw - 65px);
    margin-left: auto;
    margin-right: auto;
  }

  #main-navbar > a.active.nav-link > div {
    padding: 0px !important;
    box-sizing: border-box;
    /* border-top: 2px solid #FEF5D3!important;
    border-bottom: 2px solid #FEF5D3!important; */
    border-left: none;
    color: #FED9E0 !important;
    background-color: #fef5d31a !important;
    width: 100% !important;
    height: 100% !important;
  }

  #basic-navbar-nav {
    width: calc(100vw - 65px);
    box-sizing: border-box;
    border-right: none;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: center!important;
  }

  #main-navbar {
    box-sizing: border-box;
    height: calc(calc(100% / 12) * 10);
  }

  #mobileNavFooter {
    --bs-gutter-x: 0px;
    height: 100%;
    box-sizing: border-box;
    padding-top: calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2);
    padding-bottom: calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2);
    margin-left: auto;
    margin-right: auto;
  }

  #navFooterLink {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: auto;
  }

  .navFooterLink:hover {
    cursor: pointer;
  }


  #phoneLink {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  .navFooterCol {
    box-sizing: border-box;
    border:#FED9E0 3px solid;
    /* background-color: #21534A; */
    height: 55px;
    width: 55px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    line-height: calc(100dvh / 12);
    justify-content: center;
    display: flex;
  }

  #navFooterContainer {
    display: flex;
    border-top: 1px solid #21534A;
    height: calc(calc(100% / 9) * 2);
    width: calc(calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2) + 185px);
    margin: auto;
    margin-top: 0px;
  }

  #mobileNavDivider {
    border-bottom: 1px solid #21534A;
    width: calc(calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2) + 185px);
    margin: auto;
  }
}

.div.dropdown-menu.show {
  overflow: hidden!important;
}

#mainScreenContent {
  min-height: calc(calc(100dvh - 41.27vw) - 284.5px);
}

@media(min-width: 992px) {
  #mainScreenContent {
    min-height: calc(calc(100dvh - 28.79vw) - 284.5px);
  }
}

@media(min-width: 768px) {
  .offcanvas-backdrop.show {
    opacity: 0;
}
}

@media(min-width: 576px) and (max-width: 991.5px) {
  .big-logo {
    width: 41.27vw;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .container, .container-sm {
    max-width: none;
  }

  .logo-row {
    justify-content: center;
    margin-top: 30px;
    /* margin-left: 50px; */
    /* margin-right: 50px; */
    margin-bottom: 0px;
    align-items: center;
  }

  #swiper-slide {
    position:absolute;
    right:25.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    aspect-ratio: 1/1;
    color: #fff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
  
    background-color: rgba(0, 0, 0, 0);
  
    border-radius: 0px;
  }
  
  #swiper-slide-image {
    object-fit: cover;
    object-position: center;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
  
    z-index: 0;
  }
}

@media(min-width: 768px) and (max-width: 991.5px) {
  .big-logo {
    width: 41.27vw;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .container, .container-sm {
    max-width: none;
  }

  .logo-row {
    justify-content: center;
    margin-top: 30px;
    /* margin-left: 50px; */
    /* margin-right: 50px; */
    margin-bottom: 0px;
    align-items: center;
  }

  #swiper-slide {
    position:absolute;
    right:0%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    height: 100%;
    aspect-ratio: 1/1;
    color: #fff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
  
    background-color: rgba(0, 0, 0, 0);
  
    border-radius: 0px;
  }
  
  #swiper-slide-image {
    object-fit: cover;
    object-position: center;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
  
    z-index: 0;
  }
}

@media(max-width: 575.5px) {
  .logo-row {
    margin-top: calc(54.8px + 15px);
    margin-left: 11.76vw;
    margin-right: 11.76vw;
    margin-bottom: 0px;
  }
}

#mainScreenContent {
  padding-top: 0px!important;
}

@media (max-height: 487.5px) and (max-width: 767.5px) {
  #mobileNavHeader {
    height: calc(calc(100dvh / 10) * 3);
  }
  #mobileNavFooter {
    display: none;
  }

  #main-navbar {
    height: calc(calc(100dvh / 10) * 7);
  }

  #main-navbar > a.nav-link > div {
    padding: 0px !important;
    height: calc(calc(100dvh / 10) * 1) !important;
    line-height: calc(calc(100dvh / 10) * 1) !important;
    width: calc(calc(calc(calc(calc(100dvh / 12) * 2) - 55px) / 2) + 185px);
    max-width: calc(100vw - 65px);
    margin-left: auto;
    margin-right: auto;
  }
}

@media(max-width: 575.5px){
  #product-col {
    margin-bottom: 0px !important;
  }

  .productCard {
    margin-top: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    height: auto;
  }

  .productCardTitle {
    margin: 0px !important;
    /* margin-top: 15px !important; */
    /* margin-left: 15px !important; */
    padding-bottom: 7.5px !important;
    text-align: left !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 0.9rem;
  }

  .tattooCardTitle {
    margin: 0px !important;
    /* margin-top: 15px !important; */
    /* margin-left: 15px !important; */
    padding-bottom: 0px !important;
    text-align: center !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 1rem;
  }

  .productCardPrice {
    padding-top: 7.5px !important;
    /* margin-bottom: 15px !important; */
    /* margin-right: 15px !important; */
    text-align: right !important;
    color: #FED9E0 !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 0.9rem;
  }

  .tattooCardPrice {
    padding-top: 15px !important;
    /* padding-bottom: 7.5px !important; */
    margin: 0px;
    /* margin-bottom: -15px; */
    /* margin-bottom: 15px !important; */
    /* margin-right: 15px !important; */
    text-align: center !important;
    color: #FED9E0 !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 1rem;
  }

  .tattoo-card-exclusive {
    text-align: center;
    /* height: 35%; */
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    text-decoration-line: none;
    font-size: 1rem;
    /* margin: 0px !important; */
    /* margin-top: 7.5px !important; */
    /* padding-top: 7.5px !important; */
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    text-align: center !important;
    color: #FED9E0 !important;
    vertical-align: text-bottom !important;
    /* position: absolute; */
    /* bottom: 15px; */
  }

  .product-card-image {
    object-fit: cover;
    object-position: center;
    /* aspect-ratio: 1/1; */
    border:#1a1a1a solid 3px;
  }
}

@media(min-width: 576px){
  #product-col {
    margin-bottom: 5px;
  }

  .productCard {
    margin-top: 0px !important;
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
    margin-bottom: 5px !important;
    height: 100% !important;
  }

  .productCardBody {
    height: 100% !important;
  }

  .productCardTitle {
    margin: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: calc(15px + 17.275px) !important;
    text-align: left !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 0.9rem;
  }

  .tattooCardTitle {
    margin: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 7.5px !important;
    text-align: center !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 1rem;
  }

  .productCardPrice {
    margin: 0px !important;
    margin-top: auto !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    text-align: right !important;
    color: #FED9E0 !important;
    vertical-align: text-bottom !important;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 0.9rem;
  }

  .tattooCardPrice {
    margin: 0px !important;
    margin-top: auto !important;
    padding-top: 7.5px !important;
    padding-bottom: calc(1rem + 15px) !important;
    padding-right: 0px !important;
    text-align: center !important;
    color: #FED9E0 !important;
    vertical-align: text-bottom !important;
    position: absolute;
    bottom: 15px;
    right: 0px;
    width: 100%;
    font-size: 1rem;
  }

  .tattoo-card-exclusive {
    text-align: center;
    /* height: 35%; */
    margin: 0px;
    padding-top: 15px;
    padding-bottom: 0px;
    text-decoration-line: none;
    font-size: 1rem;
    /* margin: 0px !important; */
    /* margin-top: 7.5px !important; */
    /* padding-top: 7.5px !important; */
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: center !important;
    color: #FED9E0 !important;
    vertical-align: text-bottom !important;
    position: absolute; 
    bottom: 15px;
    right: 0px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    width: 100%;
  }

  .product-card-image {
    border-bottom: none !important;
  }

  .productCardImage {
    aspect-ratio: 1/1 !important;
  }
  
  #productRow {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media(min-width: 768px){
  #product-col {
    margin-bottom: 5px;
  }

  .productCard {
    margin-top: 0px !important;
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
    margin-bottom: 5px !important;
    height: 100% !important;
  }

  .productCardBody {
    height: 100% !important;
  }

  .productCardTitle {
    margin: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: calc(15px + 17.275px) !important;
    text-align: left !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 0.9rem;
  }

  .tattooCardTitle {
    margin: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 7.5px !important;
    text-align: center !important;
    /* height: 50%; */
    box-sizing: border-box;
    font-size: 1rem;
  }

  .productCardPrice {
    margin: 0px !important;
    margin-top: auto !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    text-align: right !important;
    color: #FED9E0 !important;
    vertical-align: text-bottom !important;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 0.9rem;
  }

  .tattooCardPrice {
    margin: 0px !important;
    margin-top: auto !important;
    padding-top: 7.5px !important;
    padding-bottom: calc(1rem + 15px) !important;
    padding-right: 0px !important;
    text-align: center !important;
    color: #FED9E0 !important;
    vertical-align: text-bottom !important;
    /* position: absolute; */
    /* bottom: 15px; */
    /* right: 15px; */
    font-size: 1rem;
  }

  .tattoo-card-exclusive {
    text-align: center;
    /* height: 35%; */
    margin: 0px;
    padding-top: 15px;
    padding-bottom: 0px;
    text-decoration-line: none;
    font-size: 1rem;
    /* margin: 0px !important; */
    /* margin-top: 7.5px !important; */
    /* padding-top: 7.5px !important; */
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    text-align: center !important;
    color: #FED9E0 !important;
    vertical-align: text-bottom !important;
    /* position: absolute; */
    /* bottom: 15px; */
  }

  .product-card-image {
    border-bottom: none !important;
  }

  .productCardImage {
    aspect-ratio: 1/1 !important;
  }
  
  #productRow {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 374.5px) {
  #aboutScreenTextCard {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    --bs-card-border-width: 0px;
    border:#FEF5D3 3px solid;
    border-top: none;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    color:#FEF5D3;
    text-align: center;
    background-color: #1a1a1a;
  }
  
  #aboutScreenText {
    border-top: 1px solid #FEF5D3;
    border-bottom: 1px solid #FEF5D3;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 0.70rem;
  }
}

@media (min-width: 375px) {
  #aboutScreenTextCard {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    --bs-card-border-width: 0px;
    border:#FEF5D3 3px solid;
    border-top: none;
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    color:#FEF5D3;
    text-align: center;
    background-color: #1a1a1a;
  }
  
  #aboutScreenText {
    border-top: 1px solid #FEF5D3;
    border-bottom: 1px solid #FEF5D3;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 0.80rem;
  }
}

@media (min-width: 425px) {
  #aboutScreenTextCard {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    --bs-card-border-width: 0px;
    border:#FEF5D3 3px solid;
    border-top: none;
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    color:#FEF5D3;
    text-align: center;
    background-color: #1a1a1a;
  }
  
  #aboutScreenText {
    border-top: 1px solid #FEF5D3;
    border-bottom: 1px solid #FEF5D3;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 0.90rem;
  }
}

@media (min-width: 576px) {
  #aboutScreenTextCard {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    --bs-card-border-width: 0px;
    border:#FEF5D3 3px solid;
    /* border-top: none; */
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    color:#FEF5D3;
    text-align: center;
    background-color: #1a1a1a;
  }
  
  #aboutScreenText {
    border-top: 1px solid #FEF5D3;
    border-bottom: 1px solid #FEF5D3;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 0.90rem;
  }
}

#productGoBackButton {
  border:#21534A solid 3px;
  border-radius: 15px;
  background-color: #1a1a1a;
  color:#FED9E0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

#productGoBackButton:active {
  border:#1a1a1a solid 2px;
  border-radius: 15px;
  background-color: #FED9E0;
  color:#1a1a1a;
}

#productDetailsNameItem {
  padding: 0px;
  border-bottom: 0px;
  background-color: #1a1a1a;
  color:#FEF5D3;
}

#productDetailsName {
  text-align: center;
  margin: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  border-bottom: 1px solid #FEF5D3;
}

#productDetailsDescriptionItem {
  padding: 0px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 15px;
  background-color: #1a1a1a;
  color: #FED9E0;
  border-bottom: 1px solid #FEF5D3;
}

#productDetailsCard {
  background-color: #1a1a1a;
  border: none;
}

#productDetailsPriceItem {
  background-color: #1a1a1a;
  margin: 0;
  padding: 0;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

#productDetailsPriceRow {
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;
  color: #FEF5D3;
  padding-left: 15px;
  padding-right: 15px;
}

#productDetailsQuantityItem {
  background-color: #1a1a1a;
  color:#FEF5D3;
  margin: 0;
  padding: 0;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #FEF5D3;
}

#productDetailsQtyRow {
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;
  color: #FEF5D3;
}

#productDetailsAddItem {
  background-color: #1a1a1a;
  color:#FEF5D3;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  border: none;
}

#addToCartButton {
  border: 3px solid #FED9E0;
  border-radius: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  color: #FED9E0;
  /* width: 100%; */
}

#addToCartButton:active {
  border: 3px solid #FED9E0;
  background-color: #FED9E0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  color: #1A1A1A;
}

#productDetailsQtySelect {
  color: #FED9E0;
  background-color: #1a1a1a;
  border:#FEF5D3 solid 3px;
  border-radius: 10px;
  width: auto;
  text-align: center;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 40px;
  padding-left: 20px;
  overflow: visible !important;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23FED9E0%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
  background-position: right 7.5px center;
}

.lgSizeAppear {
  display: none;
}

@media (max-width: 767.5px) {
  #desktopCartButton {
    display: none;
  }
}

@media (min-width: 768px) {
  #desktopCartButton {
    display: flex;
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: #1A1A1A;
    border-radius: 50%;
    border:#FEF5D3 solid 3px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 5;
    transition: transform .2s;
  }

  #desktopCartButton:hover {
    display: flex;
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: #21534A;
    border-radius: 50%;
    border:#FEF5D3 solid 3px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 5;
    transform: scale(1.15);
  }

  #desktopCartButton:active {
    display: flex;
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: #21534A;
    border-radius: 50%;
    border:#FEF5D3 solid 3px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 5;
    transform: scale(1.15);
  }

  #desktopCartButton.nav-link.active {
    display: flex;
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: #1A1A1A;
    border-radius: 50%;
    border:#FEF5D3 solid 3px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 5;
  }

  #desktopCartButton.nav-link.active:active {
    display: flex;
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: #21534A;
    border-radius: 50%;
    border:#FEF5D3 solid 3px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 5;
  }

  #desktopCartButton.nav-link.active:hover {
    display: flex;
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: #21534A;
    border-radius: 50%;
    border:#FEF5D3 solid 3px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 5;
  }

  #desktopCartCounter {
    background-color: #1A1A1A;
    border: 2px solid #FEF5D3;
    color: #FEF5D3;
  }
}

@media (max-width: 374.5px) {
  #productDetailsName {
    font-size: 1.1rem;
  }

  #productDetailsDescriptionItem {
    font-size: 0.825rem;
  }
}

#productDetailsAddItem {
  align-items: center;
  display: flex;
  padding-left: 3px;
  padding-right: 3px;
}

#descriptionPriceRow {
  height: 100%;
}

#descriptionPriceRow > div.col-lg-7.col-md-12.col-sm-12 {
  margin-top: auto;
  margin-bottom: auto;
}

#productDetailsAddCol {
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 576px) and (max-width: 767.5px) {
  #productDetailsRow {
    margin: 0px;
    padding: 0px;
    background-color: #1A1A1A;
  }

  #productDetailsImageCol {
    padding-right: 2px;
    padding-left: 0px;
    border-top:#1A1A1A solid 2px;
    border-left:#1A1A1A solid 2px;
    border-bottom:#1A1A1A solid 2px;
  }

  #productDetailsInfoCol {
    padding-left: 2px;
    padding-right: 0px;
  }

  #productDetailsPriceCol {
    padding-left: 2px;
  }

  #productDetailsName {
    font-size: 1rem;
  }

  #productDetailsImageCol {
    position: sticky;
    top: 4px;
  }

  #productDetailsImageCol > img {
    /* border-radius: 7.5px; */
  }

  #productDetailsDescriptionItem {
    font-size: 0.825rem;
  }

  #productInfoListGroup {
    height: 100%;
  }

  #productDetailsDivider {
    border-top: 1px solid #FEF5D3;
    margin-right: 15px;
    margin-left: 15px;
  }

  #productDetailsDescriptionItem {
    border-bottom: none;
  }

  #productDetailsAddItem {
    align-items: center;
    display: flex;
  }
}

@media (min-width: 576px) and (max-width: 991.5px) {
  #productDetailsRow {
    margin: 0px;
    padding: 0px;
    background-color: #1A1A1A;
  }

  #productDetailsImageCol {
    padding-right: 2px;
    padding-left: 0px;
    border-top:#1A1A1A solid 2px;
    border-left:#1A1A1A solid 2px;
    border-bottom:#1A1A1A solid 2px;
  }

  #productDetailsInfoCol {
    padding-left: 2px;
    padding-right: 0px;
  }

  #productDetailsName {
    font-size: 1.3rem;
  }

  #productDetailsImageCol {
    position: sticky;
    top: 4px;
  }

  #productInfoListGroup {
    height: 100%;
  }

  #productDetailsDivider {
    border-top: 1px solid #FEF5D3;
    margin-right: 15px;
    margin-left: 15px;
  }

  #productDetailsDescriptionItem {
    border-bottom: none;
  }

  #productDetailsAddItem {
    align-items: center;
    display: flex;
  }

  #productInfoListGroup {
    justify-content: center;
    align-items: center;
  }

  #productDetailsCard {
    height: 100%;
  }
  
  #descriptionPriceRow {
    height: auto;
  }

  #productDetailsNameItem {
    /* margin-top: auto; */
    margin-bottom: auto;
  }

  #productInfoListGroup {
    margin-top: auto;
    margin-bottom: auto;
  }

  #productDetailsName {
    margin-top: auto;
    margin-bottom: auto;
    align-self: center;
    border-bottom: none;
  }

  #productDetailsNameItem {
    
  }

  #productDetailsNameItem {
    height: 100%;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #FEF5D3;
    margin-left: 15px;
    margin-right: 15px;
  }

  #productDetailsDescriptionItem {
    padding-top: 15px;
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) {
  #productDetailsInfoCol {
    vertical-align: middle;
  }

  #productDetailsCard {
    align-self: center;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
  }

  #productInfoListGroup {
    margin-top: auto;
    margin-bottom: auto;
  }

  #productDetailsDescriptionItem {
    border-bottom: none;
    margin-left: 1px;
    align-items: center;
  }

  #productDetailsRow {
    margin: 0px;
    padding: 0px;
    background-color: #1A1A1A;
  }

  #productDetailsImageCol {
    padding-right: 2px;
    padding-left: 0px;
    border-top:#1A1A1A solid 2px;
    border-left:#1A1A1A solid 2px;
    border-bottom:#1A1A1A solid 2px;
  }

  #productDetailsInfoCol {
    padding-left: 2px;
    padding-right: 0px;
  }

  #productDetailsAddCol {
    padding-left: 0px;
    padding-right: 0px;
  }

  #productDetailsName {
    margin-right: 0px;
    border-bottom: none;
    margin: auto;
    margin-top: calc(1.5rem + 15px);
    margin-bottom: calc(1.5rem + 15px);
  }

  #productDetailsNameItem {
    margin-right: 0px;
    margin-left: 11px;
    margin-right: 15px;
    border-bottom: 1px solid #FEF5D3;
  }

  #productDetailsPriceRow {
    padding-left: 0px;
    padding-right: 0px;
  }

  #productDetailsQuantityItem {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  #productDetailsImageCol {
    position: sticky;
    top: 4px;
  }

  #productInfoListGroup {
    height: 100%;
  }

  #productDetailsDivider {
    border-top: 1px solid #FEF5D3;
    margin-right: 15px;
    margin-left: 15px;
  }

  #productDetailsDescriptionItem {
    border-bottom: none;
    margin-right: 0px;
    padding-right: 15px;
    border-right: 1px solid #FEF5D3;
  }

  .lgSizeDisappear {
    display: none !important;
  }

  .lgSizeAppear {
    display: block !important;
  }

  #productDetailsQtySelect {
    padding-right: 2.9rem;
  }

  #descriptionPriceRow {
    margin: 0px;
  }

  #productDetailsPriceItem {
    /* margin-right: 15px; */
  }

  #productDetailsQuantityItem {
    /* margin-right: 15px; */
  }

  #productDetailsAddItem {
    align-items: center;
    /* padding-right: 15px; */
    display: flex;
  }

  #productDetailsNameItem {
    /* height: 100%; */
    align-items: center;
    display: flex;
  }

  #productDetailsName {
    margin-top: calc(calc(1.5rem + 30px) / 2);
    margin-bottom: calc(calc(1.5rem + 30px) / 2);
    align-self: center;
  }

  #productDetailsDescriptionItem {
    padding-bottom: 0px;
    margin-bottom: 15px;
  }

  #descriptionPriceRow > div.col-lg-7.col-md-12.col-sm-12 {
    padding-right: 0px;
  }

  #productDetailsDescriptionItem {
    border-right: none;
    margin-bottom: 15px;
  }

  #productDetailsAddCol {
    padding-left: 15px;
    padding-right: 15px;
    /* border-left: 1px solid #FEF5D3; */
  }

  #productDetailsNameItem {
    margin-bottom: 15px;
  }

  #productDetailsDescriptionItem {
    margin-top: 0px;
  }

  #productDetailsAddItem {
    margin-bottom: 15px;
  }

  #descriptionPriceRow {
    margin-bottom: 15px;
  }
}

.cartRow {
  margin: 0px;
  /* background-color: #1A1A1A; */
}

.cartCol {
  padding: 0px;
}

#cartListGroupItem {
  padding: 0px;
  margin-bottom: 4px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #1A1A1A;
}

#cartItemNameCol {
  text-align: center;
  padding: 7.5px;
  display: flex;
}

#cartItemNameLink {
  color: #FEF5D3;
  margin: auto;
}

#cartItemNameLink:hover {
  color: #FED9E0;
}

#cartItemNameLink:active {
  color: #FED9E0;
}

.cartItemInfoCol {
  padding: 7.5px;
}

#cartItemQtyRow {
  height: 100%;
}

.cartItemQtyCol {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px;
}

#cartDeleteRow {
  display: flex;
  padding: 7.5px;
}

#cartDeleteButton {
  background-color: darkred;
  border-radius: 10px;
}

.cartRowBlack {
  background-color: #1A1A1A;
}

#cartSubtotalCard {
  background-color: #1A1A1A;
  border: none;
  overflow:hidden;
}

#cartTotalItemCount {
  text-align: center;
  color: #FEF5D3;
  margin-bottom: 0px;
}

#cartSubtotalValue {
  text-align: center;
  color: #FED9E0;
  margin-bottom: 0px;
}

/* @media (min-width: 576px) and (max-width: 991.5px) {
  #cartSubtotalValue {
    padding-bottom: 8px;
  }
} */

#cartSubtotalValue {
  text-align: center;
  color: #FED9E0;
  margin-bottom: 0px;
}

#cartCheckoutButtonContainer {
  justify-content: center;
  display: flex;
  background-color: #1A1A1A;
  padding-bottom: 16px;
}

#cartSubtotalContainer {
  border-bottom: none;
  background-color: #1A1A1A;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

@media (min-width: 768px) {
  #cartSubtotalContainer {
    padding-bottom: 8px;
  }
}

@media (min-width: 992px) {
  #cartSubtotalContainer {
    padding-top: 0px;
  }
}

#cartCheckoutButton {
  background-color: #21534A;
  color: #FED9E0;
}

#checkoutListGroup {
  background-color: #1A1A1A;
}

#aboutScreenImageCard {
  position: sticky;
  top: 4px;
  max-height: calc(100dvh - 6px);
}

@media (max-width: 767.5px) {
  .squareImage {
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1/1;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .squareImage {
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1/1;
    width: 100%;
  }
}

@media (max-width: 767.5px) {
  .photo-card {
    overflow: visible;
    aspect-ratio: auto;
  }
}

.cartItemInfoCol {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

#subtotalItemCount {
  font-size: 1rem;
}

#cartCheckoutButton {
  border-radius: 10px;
  min-height: 72px;
}

.cartQtyOption {
  text-align: left;
}

#cartSubtotalCol {
  border-radius: 10px;
  overflow: hidden;
  /* margin-top: 12px; */
}

@media (max-width: 991.5px) {
  #cartTotalItemCount {
    margin-bottom: 0px;
    padding-top: 8px;
  }

  #cartCheckoutButtonContainer {
    margin-bottom: 0px;
    height: 100%;
    padding: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (min-width: 576px) {
  #cartDeleteButton { 
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    width: 100%;
    height: 50%;
    margin: auto;
  }

  #cartDeleteRow {
    padding: 2px;
    padding-right: 3px;
  }
}

@media (min-width: 992px) {
  #cartSubtotalCard {
    margin-left: 4px;
    border-radius: 10px;
    position: sticky;
    top: 28px;
  }
  
  #cartSubtotalCol {
    border-radius: 0px;
    overflow: visible
  }
}

/* Review Order Screen Styles*/

@media (min-width: 992px) {
  #orderItemsHeader {
    margin: auto;
    color:#FEF5D3;
    background-color: #1A1A1A;
    width: 100%;
    padding: 1.4rem;
    margin-right: 5px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }

  #orderSummaryHeader {
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    color:#FED9E0;
    background-color: #1A1A1A;
    width: 100%;
    padding: 1.4rem;
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
  }

  #orderItemsCol {
    display: flex;
  }

  #orderSummaryCol {
    display: flex;
  }

  #itemListCol {
    padding-right: 5px;
    padding-top: 0px;
  }

  .orderItemRow {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    /* background-color: #a1a1a1; */
    /* border: #1A1A1A solid 1.5px; */
    /* border-right: #1A1A1A solid 3px;
    border-left: #1A1A1A solid 3px; */
    border-bottom: 1px solid #FEF5D3
  }

  #orderItemListFooter {
    height: calc(1.4rem - 5px);
    background-color: #1A1A1A;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  #orderItemsContainer {
    /* background-color: #1A1A1A; */
    /* border-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
    position: sticky;
    top: 4px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  #orderSummaryCard {
    overflow: hidden;
  }

  .orderSummaryListItem {
    border: none;
    padding: 12px;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 576px) and (max-width: 991.5px) {
  #orderItemsHeader {
    margin: auto;
    color:#FEF5D3;
    background-color: #1A1A1A;
    width: 100%;
    padding: 1.4rem;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }

  #orderSummaryHeader {
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    color:#FED9E0;
    background-color: #1A1A1A;
    width: 100%;
    padding: 1.4rem;
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
  }

  #orderItemsCol {
    display: flex;
  }

  #orderSummaryCol {
    display: flex;
  }

  #itemListCol {
    /* padding-right: 5px; */
    padding-top: 0px;
  }

  .orderItemRow {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    /* background-color: #a1a1a1; */
    /* border: #1A1A1A solid 1.5px; */
    /* border-right: #1A1A1A solid 3px;
    border-left: #1A1A1A solid 3px; */
    border-bottom: 1px solid #FEF5D3
  }

  #orderItemListFooter {
    display: none;
    height: calc(1.4rem - 5px);
    background-color: #1A1A1A;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  #orderItemsContainer {
    /* background-color: #1A1A1A; */
    /* border-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
    /* position: ; */
    /* max-height: 100%; */
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* #orderItemsContainer > div:nth-child(2) {
    border-top: 1px solid #FEF5D3;
  } */

  /* #orderItemsContainer > div:nth-last-child(2) {
    border-bottom: none;
  } */

  #orderSummaryCard {
    overflow: hidden;
    margin-top: 5px;
    /* max-width: 783.6px; */
    margin-left: auto;
    margin-right: auto;
  }

  .orderSummaryListItem {
    border: none;
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    /* width: 278.4px; */
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575.5px) {
  #orderItemsHeader {
    margin: auto;
    color:#FEF5D3;
    background-color: #1A1A1A;
    width: 100%;
    padding: 1.4rem;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }

  #orderSummaryHeader {
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    color:#FED9E0;
    background-color: #1A1A1A;
    width: 100%;
    padding: 1.4rem;
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
  }

  #orderItemsCol {
    display: flex;
  }

  #orderSummaryCol {
    display: flex;
  }

  #itemListCol {
    /* padding-right: 5px; */
    padding-top: 0px;
  }

  .orderItemRow {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    /* background-color: #a1a1a1; */
    /* border: #1A1A1A solid 1.5px; */
    /* border-right: #1A1A1A solid 3px;
    border-left: #1A1A1A solid 3px; */
    border-bottom: 1px solid #FEF5D3
  }

  #orderItemListFooter {
    display: none;
    height: calc(1.4rem - 5px);
    background-color: #1A1A1A;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  #orderItemsContainer {
    /* background-color: #1A1A1A; */
    /* border-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
    /* position: ; */
    /* max-height: 100%; */
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* #orderItemsContainer > div:nth-child(2) {
    border-top: 1px solid #FEF5D3;
  } */

  #orderSummaryCard {
    overflow: hidden;
    margin-top: 5px;
    /* max-width: 783.6px; */
    margin-left: auto;
    margin-right: auto;
  }

  .orderSummaryListItem {
    border: none;
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  /* #orderItemsContainer > div:nth-last-child(2) {
    border-bottom: none;
  } */
}

/* TATTOO PAYMENT SCREEN */
#tattooPaymentDetailsRow {
  margin: 0px;
  padding: 0px;
  background-color: #1A1A1A;
  border-radius: 10px;
  overflow: hidden;
}

/* #tattooPaymentDetailsCol { */
    /* border-radius: 10px; */
    /* overflow: hidden; */
/* } */

@media (min-width: 576px) {
  #tattooPaymentImage {
    border-radius: 10px;
  }
}

@media (min-width: 992px) {
  #tattooPaymentSummaryCol {
    padding-left: 5px;
  }

  #tattooPaymentDetailsRow {
    position: sticky;
    top: 5px;
  }
}

@media (max-width: 575.5px) {
  .cartCountrySelect {
    margin-top: 16px;
  }

  .cartStateSelect {
    margin-top: 16px;
  }
}

@media (min-width: 576px) and (max-width: 767.5px) {
  .cartCountrySelect {
    margin-top: 16px;
  }

  .cartStateSelect {
    margin-top: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .cartStateSelect {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media (min-width: 992px) {
  .cartStateSelect {
    margin-top: 8px;
  }
}